<template>
  <div id="app">
    <ul class="infinite-list" :style="'overflow:auto; height: ' + 80 + 'vh'" id="ulMsg">
      <li v-for="(item, index) in msgList" :key="index" class="infinite-list-item">
        <el-row type="flex">
          <el-col :span="4">
            <div v-if="item.thUsrId !== userInfo.lUsrId" style="margin-top: 30px">
              <p style="overflow: hidden;margin: 0px">{{ item.thName }}</p>
              <el-avatar src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"></el-avatar>
            </div>
          </el-col>
          <el-col :span="16">
            <p style="margin-top: 0px; text-align: center">{{ item.thDate }}</p>
            <el-card shadow="always">
              <p class="'text_left test2">
                {{ item.thMsg }}
              </p>
            </el-card>
          </el-col>
          <el-col :span="4" style="text-align: center">
            <div v-if="item.thUsrId === userInfo.lUsrId" @click="openChangeUserName" style="margin-top: 30px">
              <p style="overflow: hidden;margin: 0px">{{ item.thName }}</p>
              <el-avatar src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"></el-avatar>
            </div>
          </el-col>
        </el-row>
        <el-divider></el-divider>
      </li>
    </ul>
    <el-row type="flex" justify="end">
      <el-col>
        <el-input placeholder="请输入内容" v-model="msgData.ThMsg" class="input-with-select">
          <el-button slot="append" icon="el-icon-edit-outline" @click="editMsgVisible = true"></el-button>
        </el-input>
      </el-col>
      <el-col :span="6">
        <el-button @click="sentMsg">发送</el-button>
      </el-col>
    </el-row>


    <el-dialog
        title="修改名称"
        :visible.sync="changeUserNameVisible"
        width="80%">
      <div class="demo-input-suffix">
        <el-input
            placeholder="输入名称"
            prefix-icon="el-icon-search"
            v-model="msgData.thName">
        </el-input>
      </div>
      <span slot="footer" class="dialog-footer">
    <el-button type="primary" @click="changeUserName">确 定</el-button>
  </span>
    </el-dialog>


    <el-dialog
        title="编辑消息"
        :visible.sync="editMsgVisible"
        width="80%">
      <div class="demo-input-suffix">
        <el-input
            type="textarea"
            :rows="10"
            placeholder="嘿, 记录下来...."
            v-model="editMsg">
        </el-input>
      </div>
      <span slot="footer" class="dialog-footer">
    <el-button @click="editMsgVisible = false">取 消</el-button>
    <el-button type="primary" @click="editMsgVisible = false; msgData.ThMsg = editMsg;">确 定</el-button>
  </span>
    </el-dialog>
  </div>
</template>

<script>
import m3u8 from "@/request/hole/index";

export default {
  name: "treeHoleMsg",
  data() {
    return {
      holeType: 'all',
      appHeight: 0,
      editMsg: '',
      editMsgVisible: false,
      changeUserNameVisible: false,
      userInfo: null,
      askIndex: new m3u8(),
      msgList: [],
      msgData: {
        "thUsrId": 1,
        "thName": 'C.',
        "thDate": new Date().getTime(),
        "thMsg": ''
      },
      param: {
        indexI: 0
      },
      timer: null,
      isAsk: true,
      prevPath: ''
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      // 通过 `vm` 访问组件实例,将值传入fromPath
      vm.prevPath = from.path
    })
  },
  created() {
    const userInfo = this.$store.state.user.userInfo
    if (userInfo !== null) {
      this.userInfo = userInfo
      this.msgData.thName = userInfo.lUsrName
    } else {
      this.$router.push({path: '/login'})
    }
  },
  methods: {
    /**
     * 发送消息
     */
    sentMsg() {
      const msg = this.defineMsgInfo()
      msg.thMsg = this.msgData.ThMsg
      this.askIndex.sentMsg(msg, this.holeType)
      this.msgData.ThMsg = ''

    },
    /**
     * 获取消息
     */
    getMsgDataList() {
      this.askIndex.getMsgDataList(this.param, this.holeType).then(res => {
        if (res.length > 0) {
          this.msgList = this.msgList.concat(res)
        }
        this.isAsk = true
      })
    },
    /**
     * 初始化消息对象
     * @returns {{thUsrId: *, thDate: number, thMsg: string, thName: string}}
     */
    defineMsgInfo() {
      return {
        "thUsrId": this.userInfo.lUsrId,
        "thName": this.userInfo.lUsrName,
        "thDate": new Date().getTime(),
        "thMsg": ''
      }
    },
    /**
     * 打开改名弹框
     */
    openChangeUserName() {
      this.changeUserNameVisible = true
    },
    /**
     * 修改名称
     */
    changeUserName() {
      this.changeUserNameVisible = false
    }
  },
  mounted() {
    const thId = this.$route.params.thId
    if (thId == null) {
      if (this.prevPath === "/") {
        this.$router.push({path: '/treeHole/group'})
      } else {
        this.$router.go(-1)
      }
      // this.$router.push({
      //   path: '/treeHole/group',
      // })
      return;
    } else {
      this.holeType = thId;
    }

    this.timer = setInterval(() => {
      if (this.isAsk) {
        this.isAsk = false
        this.getMsgDataList()
      }
    }, 1000)
  },
  watch: {
    msgList(newItem, oldItem) {
      this.$nextTick(() => {
        const msg = document.getElementById('ulMsg') // 获取对象
        msg.scrollTop = msg.scrollHeight // 滚动高度
      })
      this.param.indexI = this.msgList.length
    },
  },
  destroyed() {
    clearInterval(this.timer);
    this.timer = null;
  }

}
</script>

<style scoped>
ul {
  padding-left: 0px;
}

li {
  margin-top: 5px;
  list-style: none;
}

.text_left {
  text-align: left;
}

.text_right {
  text-align: right;
}

p.test2 {
  margin: 0px;
  word-break: break-all;
}
</style>
